import {useGlobalState} from "../../context/GlobalProvider";

const Cart = () => {
  const {cartItems, setCartItems} = useGlobalState();

  const handleCheckout = async () => {
    const messages = cartItems.map((cartItem, index) => {
      const imageLink = `${process.env.REACT_APP_API_URL}/${cartItem.image}`;
      let content = `${index + 1}.${cartItem.title}\nImage: ${imageLink}\nQuantity: ${
        cartItem.quantity
      }`;

      if (cartItem.size) {
        content += `\nSize: ${cartItem.size}`;
      }

      if (cartItem.price) {
        content += `\nPrice: ${cartItem.price}`;
      }

      return content;
    });

    const formattedMessages = messages.join("\n\n");

    const encodedMessage = encodeURIComponent(`New order - Haset Shop \n\n${formattedMessages}`);

    const phoneNumber = "971502194824";
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.location.href = whatsappLink;
  };

  const handleDelete = index => {
    const filteredItems = cartItems.filter((current, currentIndex) => currentIndex !== index);
    setCartItems(filteredItems);
    localStorage.setItem("cartItems", JSON.stringify(filteredItems));
  };

  const handleQuantity = (e, product) => {
    const name = e.target.name;

    if (name == "increase") {
      const updatedCartItems = cartItems.map(item =>
        item.product == product ? {...item, quantity: Number(item.quantity) + 1} : item
      );
      localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
      setCartItems(updatedCartItems);
    } else {
      const updatedCartItems = cartItems.map(item =>
        item.product == product ? {...item, quantity: Number(item.quantity) - 1} : item
      );
      localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
      setCartItems(updatedCartItems);
    }
  };

  return (
    <div className="container-md ">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <h2 className="mt-2 mb-0 text-left text-dark shoppingCart">SHOPPING CART</h2>
        </div>

        <div>
          <button
            disabled={cartItems.length === 0}
            className="border text-white purchase px-3 pb-2 mt-2 fs-5 rounded"
            onClick={handleCheckout}
          >
            <span className="orderHere"> Order Here </span>
            <i className="fa fa-whatsapp whatsapp ps-1 mt-1 orderHere"></i>
          </button>
        </div>
      </div>

      <hr />
      {cartItems.length === 0 && (
        <>
          <div className="text-center h1 mt-5">
            <i className="fa-solid fa-cart-shopping "></i>
          </div>
          <p className="text-center fs-3">Your cart is currently empty</p>
        </>
      )}

      {cartItems.length > 0 && (
        <div>
          <div className=""></div>
          <div className="p-0 mt-4">
            {cartItems.map((item, index) => (
              <div key={index}>
                <div className="d-flex mb-4 justify-content-around cart">
                  <div>
                    <img
                      src={`${process.env.REACT_APP_API_URL}/${item.image}`}
                      className="d-block "
                      style={{width: 150}}
                    />
                  </div>
                  <div className="ps-2 col-6">
                    <h5 className="detail">{item.description}</h5>
                    <div className="">
                      {item.size && (
                        <div>
                          <span className="fw-bold">Size: </span> {item.size}
                        </div>
                      )}

                      {item.color !== "-" && (
                        <div>
                          <span className="fw-bold">Color: </span> {item.color}
                        </div>
                      )}
                    </div>

                    {item.price !== 0 && (
                      <div>
                        <span className="fw-bold">Price: </span> {item.price}
                      </div>
                    )}

                    <button
                      className="m-0 p-0 border-0 text-danger"
                      onClick={() => handleDelete(index)}
                    >
                      Delete
                    </button>
                  </div>
                  <div className="h-100 m-0 p-0">
                    <div className="d-flex flex-column my-2">
                      <button
                        className="text-center bg-white increaseButton"
                        id="increase"
                        name="increase"
                        onClick={e => handleQuantity(e, item.product)}
                      >
                        +
                      </button>

                      <input
                        type="number"
                        id="number"
                        value={item.quantity}
                        readOnly
                        className="text-center quantityInput"
                      />
                      <button
                        className="text-center bg-white decreaseButton"
                        id="decrease"
                        name="decrease"
                        onClick={e => handleQuantity(e, item.product)}
                      >
                        -
                      </button>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Cart;
