import {Outlet, Link} from "react-router-dom";

import Admin from "./Admin";
import Footer from "../Footer";
import {ToastContainer} from "react-toastify";
import {useGlobalState} from "../../../context/GlobalProvider";

const Header = () => {
  const {user} = useGlobalState();
  const {searchTerm, cartItems, setSearchTerm} = useGlobalState();

  return (
    <>
      <header>
        <ToastContainer />

        <div className="navigation  text-white w-100 mb-2 pb-2">
          <div className="w-100 mb-2">
            <div className="w-100 container d-flex justify-content-between">
              <div className="d-md-flex justify-content-between flex-fill">
                <div>
                  <span className="them2 fw-bold">
                    <i className="fa-solid fa-phone them2 "></i>+971502194824
                  </span>
                </div>
                <div>
                  <span className="theme ">
                    <span className="green">ይዘዙን </span>
                    <span className="yellow"> ያሉበት</span>
                    <span className="red"> እናመጣለን</span>
                  </span>
                </div>
              </div>

              <div className="flex-fill ms-auto d-flex">
                <div id="google_translate_element" className="ms-auto"></div>
              </div>
            </div>
          </div>
          <div className="d-flex container">
            <div className="div1">
              <Link to="/">
                <img src="./haset.jpg" alt="haset" style={{width: 180}} />
              </Link>
            </div>
            <div className=" div2 flex-fill mx-5">
              <div>
                <input
                  className="search form-control"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                />
              </div>
            </div>

            <div className="div3 d-flex ms-auto">
              <Link className="text-decoration-none theme" to="/cart">
                <i className="fa badge fa-lg fs-2 p-0" value={cartItems.length}>
                  &#xf07a;
                </i>
                <span className="fs-5 p-0 them2">Cart</span>
              </Link>

              {user && <Admin />}
            </div>
          </div>
          <div className="div4  container">
            <input
              className="form-control"
              type="search"
              placeholder="Search"
              aria-label="Search"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </header>

      <main className="outlet">
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

export default Header;
