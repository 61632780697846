import {Link} from "react-router-dom";
const carousel = ({productId, images, cart, LinkStatus}) => {
  const extractName = filePath => {
    const parts = filePath.split("/");
    return parts[parts.length - 1];
  };
  return (
    <div id={`product${productId}`} className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-indicators m-auto">
        {images &&
          images.length > 1 &&
          images.map((image, index) => (
            <button
              key={index}
              type="button"
              data-bs-target={`#product${productId}`}
              data-bs-slide-to={index.toString()}
              className={index == 0 ? "active bg-secondary" : "bg-secondary"}
              aria-current=""
            ></button>
          ))}
      </div>

      <div className="carousel-inner m-auto" style={cart ? {width: 200} : {}}>
        {images &&
          images.map((image, index) => (
            <div
              className={`carousel-item ${index === 0 ? "active" : ""}`}
              key={index}
              style={cart ? {width: 200} : {}}
            >
              <>
                <Link to={LinkStatus ? null : `product/${productId}`}>
                  <img
                    src={`${process.env.REACT_APP_API_URL}/${extractName(image.imageUrl)}`}
                    className="d-block w-100"
                  />
                </Link>
              </>
            </div>
          ))}
      </div>

      {images && images.length > 1 && (
        <>
          <button
            className="carousel-control-prev "
            type="button"
            data-bs-target={`#product${productId}`}
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next "
            type="button"
            data-bs-target={`#product${productId}`}
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </>
      )}
    </div>
  );
};

export default carousel;
