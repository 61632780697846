const Footer = () => {
  return (
    <footer className="text-center container mt-3">
      <div>
        <div style={{fontSize: 14}}>&copy; 2023 Haset Shop. All rights reserved.</div>
        <div style={{fontSize: 13}}>
          Website by{" "}
          <a
            className="text-decoration-none fw-bold"
            href="https://eyuel.onrender.com/"
            target="_blank"
          >
            Eyuel Begashaw
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
