import axios from "axios";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

import Form from "../../components/Globals/Form";
import {toast, ToastContainer} from "react-toastify";

import {errorMessage} from "../../util/error";
import {useGlobalState} from "../../context/GlobalProvider";

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({email: "", password: ""});
  const {setUser} = useGlobalState();

  const onSubmit = async e => {
    try {
      e.preventDefault();
      const {email, password} = formData;
      if (email === "" || password === "") {
        toast.error("All fields required");
      } else {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/login`, {
          email,
          password,
        });
        localStorage.setItem("user", JSON.stringify(response));
        setUser(response.data.token);
        navigate("/");
      }
    } catch (error) {
      toast.error(errorMessage(error));
    }
  };

  const onChange = e => {
    setFormData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className="container col-md-6 col-lg-5 col-xl-4 mx-auto mt-3">
      <ToastContainer />
      <h2>
        <i className="fas fa-user"></i> SIGN IN
      </h2>
      <Form formData={formData} onChange={onChange} onSubmit={onSubmit} type={"Login"} />
    </div>
  );
};

export default Login;
