import axios from "axios";

export const getCategory = async () => {
  const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/category/`);
  return data;
};

export const updateCategory = async (updatedCategory, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const {data} = await axios.put(
    `${process.env.REACT_APP_API_URL}/category/${updatedCategory.id}`,
    updatedCategory,
    config
  );
  return data;
};

export const createCategory = async (newCategory, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const {data} = await axios.post(
    `${process.env.REACT_APP_API_URL}/category/`,
    newCategory,
    config
  );
  return data;
};

export const deleteCategory = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const {data} = await axios.delete(`${process.env.REACT_APP_API_URL}/category/${id}`, config);
  return data;
};
