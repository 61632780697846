import {Link} from "react-router-dom";
import Carousel from "./carousel";

const Product = ({product}) => {
  return (
    <div className="card product">
      <Carousel productId={product.id} images={product.images} />

      <div className="cardBody p-1">
        <Link to={`/product/${product.id}`} className="fs-4 text-decoration-none cardBody">
          {product.title}
        </Link>
      </div>
      {product.price !== 0 && <div className="fw-bold">{product.price} AED</div>}
      <p className="card-body p-1">{product.description}</p>
    </div>
  );
};

export default Product;
