const Form = ({formData, onChange, onSubmit}) => {
  return (
    <form onSubmit={onSubmit}>
      <div className="form-group  mb-2">
        <label htmlFor="email" className="form-label fw-bold">
          Email
        </label>
        <input
          type="email"
          className="form-control"
          id="email"
          name="email"
          value={formData.email}
          placeholder="Enter your email"
          onChange={onChange}
        />
      </div>
      <div className="form-group  mb-2">
        <label htmlFor="email" className="form-label fw-bold">
          Password
        </label>
        <input
          type="password"
          className="form-control"
          id="password"
          name="password"
          value={formData.password}
          placeholder="Enter password"
          onChange={onChange}
        />
      </div>

      <div className="form-group mt-2">
        <button type="submit" className="btn btn-sucess">
          Login
        </button>
      </div>
    </form>
  );
};

export default Form;
