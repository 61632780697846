import {createContext, useContext, useState, useEffect} from "react";
import "react-toastify/dist/ReactToastify.css";
import jwt_decode from "jwt-decode";

import productAPI from "../API/productAPI";
import * as categoryAPI from "../API/categoryAPI";
import {errorMessage} from "../util/error";
import {toast} from "react-toastify";

//Global context
const GlobalStateContext = createContext();
export const useGlobalState = () => useContext(GlobalStateContext);

export const GlobalStateProvider = ({children}) => {
  const [user, setUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [cartItems, setCartItems] = useState([]);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const storedItems = JSON.parse(localStorage.getItem("cartItems"));

    if (user && user.data && user.data.token) {
      let decoded = jwt_decode(user.data.token);
      if (decoded) {
        setUser(user.data.token);
      } else {
        setUser(null);
      }
    } else {
      setUser(null);
    }

    if (storedItems) {
      setCartItems(storedItems);
    } else {
      setCartItems([]);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await productAPI.getProducts();
        setProducts(response);
        const response2 = await categoryAPI.getCategory();
        setCategories(response2);
      } catch (error) {
        toast.error(errorMessage(error));
      }
    };

    fetchData();
  }, []);

  const globalState = {
    user,
    setUser,
    products,
    setProducts,
    categories,
    setCategories,
    cartItems,
    setCartItems,
    searchTerm,
    setSearchTerm,
  };

  return <GlobalStateContext.Provider value={globalState}>{children}</GlobalStateContext.Provider>;
};
