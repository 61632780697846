import {useState, useEffect} from "react";
import Product from "../../components/Public/Home/Product";
import {useGlobalState} from "../../context/GlobalProvider";

const Home = () => {
  const {searchTerm, products, categories} = useGlobalState();

  useEffect(() => {
    setProducts2(products);
  }, [products]);

  const [category, setCategory] = useState("all");
  const [products2, setProducts2] = useState(products);
  const [result, setResult] = useState("NotEmpty");

  useEffect(() => {
    const handleSearch = () => {
      let filteredData = products;
      if (searchTerm.trim() !== "") {
        if (category !== "all") {
          filteredData = products.filter(
            product =>
              product.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
              (category === "" || product.category.name === category)
          );
        } else if (category === "all") {
          filteredData = products.filter(product => {
            return product.title.toLowerCase().includes(searchTerm.toLowerCase());
          });
        }
      } else if (category !== "" && category !== "all") {
        filteredData = products.filter(product => product.category.name === category);
      }
      if (products.length !== 0) {
        if (filteredData.length == 0) {
          setResult("empty");
        } else {
          setResult("NotEmpty");
        }
      }

      setProducts2(filteredData);
    };

    handleSearch();
  }, [searchTerm, category]);

  return (
    <>
      <div className="container ">
        <div className="col-md-6 m-auto">
          <label htmlFor="category">Category</label>

          <select
            id="category"
            name="category"
            className="category form-select m-0 "
            aria-label="Default select example"
            value={category}
            onChange={e => setCategory(e.target.value)}
          >
            <option defaultValue value="all">
              All
            </option>
            {categories.map(category => (
              <option value={category.name} key={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
        {result == "empty" && <p className="container fs-4 text-danger">No result</p>}

        {products2.length > 0 && (
          <div>
            <div className="container mt-3" id="products">
              {products2
                .filter(product => product.status == "on")
                .map(product => (
                  <Product key={product.id} product={product} />
                ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Home;
