const ProductForm = ({
  handleSubmit,
  handleChange,
  categories,
  inputs,
  inputFields,
  setInputFields,
  handleClear,
  handleDeleteImage,
  edit,
  loading,
}) => {
  const handleAddField = e => {
    e.preventDefault();
    setInputFields([...inputFields, {file: null, text: ""}]);
  };

  const handleFileChange = (index, event) => {
    const newInputFields = [...inputFields];
    newInputFields[index].file = event.target.files[0];
    setInputFields(newInputFields);
  };

  const handleTextChange = (index, event) => {
    const newInputFields = [...inputFields];
    newInputFields[index].text = event.target.value;
    setInputFields(newInputFields);
  };

  return (
    <form>
      <div className="d-lg-flex justify-content-around">
        <div className="flex-fill me-lg-3">
          <label for="name">Title</label>
          <div>
            <input
              className="form-control"
              placeholder="Name"
              id="name"
              type="text"
              name="title"
              value={inputs.title}
              onChange={e => handleChange(e)}
            />
          </div>

          <div>
            <label for="description">Description</label>
            <input
              className="form-control"
              placeholder="Description"
              id="description"
              type="text"
              name="description"
              value={inputs.description}
              onChange={e => handleChange(e)}
            />
          </div>

          <div>
            <label for="price">Price</label>
            <input
              className="form-control"
              id="price"
              type="number"
              name="price"
              value={inputs.price}
              onChange={e => handleChange(e)}
            />
          </div>
          <div>
            <button className="btn btn-secondary my-2" onClick={e => handleAddField(e)}>
              Upload Images +
            </button>
            <div className="imageFields">
              {inputFields.map((field, index) => (
                <div
                  key={index}
                  className="d-md-flex border oneField  p-1 align-items-center justify-content-around mb-2"
                >
                  <div className="mt-1">{index + 1}.</div>
                  {field.file && (
                    <div className="mt-1">
                      <img
                        src={URL.createObjectURL(field.file)}
                        alt={`Image ${index}`}
                        style={{maxWidth: "100px", maxHeight: "100px"}}
                      />
                    </div>
                  )}

                  <div className="mt-1">
                    <input
                      style={{width: 140}}
                      type="file"
                      name="image"
                      onChange={event => handleFileChange(index, event)}
                    />
                  </div>
                  <div className="mt-1">
                    <input
                      style={{width: 140}}
                      type="text"
                      placeholder="Image color"
                      className="p-1"
                      value={field.text}
                      onChange={event => handleTextChange(index, event)}
                    />
                  </div>

                  <button className="border-0 mx-1 mt-1" onClick={e => handleDeleteImage(e, index)}>
                    <i className="fa fa-trash text-danger" aria-hidden="true"></i>{" "}
                    <span className="text-danger">Delete</span>
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex-fill ms-lg-3">
          <div>
            <label htmlFor="category">Category</label>
            <select
              id="category"
              name="categoryId"
              className="form-select m-0"
              aria-label="Default select example"
              value={inputs.categoryId}
              onChange={e => handleChange(e)}
            >
              <option selected value="">
                Select category
              </option>
              {categories.map(category => (
                <option value={category.id}>{category.name}</option>
              ))}
            </select>
          </div>

          <div className="d-flex">
            <div className="flex-fill">
              <label htmlFor="status">Status</label>
              <select
                id="status"
                name="status"
                className="form-select m-0"
                value={inputs.status}
                onChange={e => handleChange(e)}
              >
                <option value="on">on</option>
                <option value="off">off</option>
              </select>
            </div>

            <div className="flex-fill">
              <label htmlFor="isPinned">Pinned</label>
              <select
                id="isPinned"
                name="isPinned"
                className="form-select m-0"
                value={inputs.isPinned == 1 ? 1 : 0}
                onChange={e => handleChange(e)}
              >
                <option value={1}>Pinned</option>
                <option value={0}>Not Pinned</option>
              </select>
            </div>
          </div>

          <div>
            <label for="size">Size</label>
            <input
              className="form-control"
              id="size"
              type="text"
              name="size"
              value={inputs.size}
              onChange={e => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="d-flex mt-2">
        <div className="me-2">
          <button className="btn btn-secondary" onClick={e => handleSubmit(e)}>
            {edit === true ? "Edit  " : "Add"}
            {loading && (
              <div class="spinner-border text-light spinner-border-sm ms-1" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            )}
          </button>
        </div>
        <div>
          <button className="btn btn-danger" onClick={e => handleClear(e)}>
            Clear
          </button>
        </div>
      </div>
    </form>
  );
};

export default ProductForm;
