import {BrowserRouter as Router, Routes, Route} from "react-router-dom";

//Navigation component
import Header from "./components/Globals/Header/Header";

//Admin Pages
import Products from "./pages/Admin/Products";
import Category from "./pages/Admin/category";

//Public pages

import Cart from "./pages/Public/Cart";
import Home from "./pages/Public/Home";
import Login from "./pages/Public/Login";
import ProductDetail from "./pages/Public/ProductDetail";

import {GlobalStateProvider} from "./context/GlobalProvider";
function App() {
  return (
    <GlobalStateProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Header />}>
            <Route index element={<Home />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/product/:productId" element={<ProductDetail />} />
            <Route path="/admin/products" element={<Products />} />
            <Route path="/admin/category" element={<Category />} />
          </Route>
        </Routes>
      </Router>
    </GlobalStateProvider>
  );
}

export default App;
