import {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";

import {toast} from "react-toastify";
import {errorMessage} from "../../util/error";
import * as categoryAPI from "../../API/categoryAPI";
import {useGlobalState} from "../../context/GlobalProvider";

const Category = () => {
  const {user, categories, setCategories} = useGlobalState();
  const navigate = useNavigate();

  const [edit, setEdit] = useState(false);
  const [create, setCreate] = useState(false);
  const [inputs, setInputs] = useState({id: "", name: ""});

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  const handleChange = e => {
    const name = e.target.name;
    setInputs({...inputs, [name]: e.target.value});
  };

  const handleDelete = async id => {
    if (window.confirm("Are you sure ?")) {
      try {
        await categoryAPI.deleteCategory(id, user);
        setCategories(categories.filter(value => value.id !== id));
        toast.success("Deleted Successfully");
      } catch (error) {
        toast.error(errorMessage(error));
      }
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (inputs.name === "") {
      toast.error("Please make sure all fields are filled in correctly");
    } else {
      try {
        if (edit) {
          const updated = await categoryAPI.updateCategory(inputs, user);
          setCategories(categories.map(value => (value.id === inputs.id ? updated : value)));
          toast.success("Category edited successfully");
        } else if (create) {
          const newData = await categoryAPI.createCategory(inputs, user);
          setCategories([...categories, newData]);
          toast.success("Category added successfully");
        }
      } catch (error) {
        toast.error(errorMessage(error));
      }
    }
  };

  const handleEdit = category => {
    setEdit(true);
    setCreate(false);
    setInputs({
      id: category.id,
      name: category.name,
    });
  };

  const handleCreate = () => {
    setInputs({
      id: "",
      name: "",
    });

    setEdit(false);
    setCreate(true);
  };

  const handleHide = () => {
    setEdit(false);
    setCreate(false);
  };

  return (
    <div className="w-100 container">
      <div className="w-100 text-dark fs-4 text-center py-2 mb-2 ">Category Management</div>
      {user && (
        <div className="m-2">
          <div>
            {!create && !edit && (
              <button className="btn btn-secondary mb-2" onClick={handleCreate}>
                Create
              </button>
            )}

            {(create || edit) && (
              <button className="btn btn-secondary mb-2" onClick={handleHide}>
                Hide
              </button>
            )}

            {(create || edit) && (
              <form onSubmit={e => handleSubmit(e)} className="row g-3">
                <div>
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={inputs.name}
                    onChange={e => handleChange(e)}
                    style={{width: 350}}
                  />
                  <div className="d-flex mt-2">
                    <div>
                      <button type="submit" className="btn btn-secondary">
                        {edit ? "Update" : "Create"}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}

            <table className="table table-responsive p-0">
              <thead>
                <tr>
                  <th> No</th>
                  <th> Name</th>
                  <th> Edit </th>
                  <th> Delete </th>
                </tr>
              </thead>
              <tbody>
                {categories.map((category, index) => (
                  <tr key={index}>
                    <td> {index + 1}</td>
                    <td> {category.name}</td>
                    <td>
                      <button className="border-0" onClick={() => handleEdit(category)}>
                        <i className="fas fa-edit"></i>
                      </button>
                    </td>

                    <td>
                      <button className="border-0" onClick={() => handleDelete(category.id)}>
                        <i className="fa fa-trash text-danger" aria-hidden="true"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default Category;
