import axios from "axios";

//GET - fetch all product
const getProducts = async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/products`);
  return response.data;
};

//GET - fetch one product
const getProduct = async id => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/products/${id}`);
  return response.data;
};

//PUT - update user  - admin
const updateProduct = async (id, updatedProduct, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const {data} = await axios.put(
    `${process.env.REACT_APP_API_URL}/products/${id}`,
    updatedProduct,
    config
  );
  return data;
};

//DELETE - delete user
const deleteProduct = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const {data} = await axios.delete(`${process.env.REACT_APP_API_URL}/products/${id}`, config);
  return data;
};

const createProduct = async (newProduct, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const {data} = await axios.post(`${process.env.REACT_APP_API_URL}/products/`, newProduct, config);
  return data;
};

const productService = {
  getProducts,
  getProduct,
  updateProduct,
  deleteProduct,
  createProduct,
};
export default productService;
