import {useState, useEffect} from "react";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

import {errorMessage} from "../../util/error";
import productAPI from "../../API/productAPI";
import {useGlobalState} from "../../context/GlobalProvider";
import ProductForm from "../../components/product/ProductForm";
import ProductTable from "../../components/product/ProductTable";

const Product = () => {
  const navigate = useNavigate();
  const {user, categories, products, setProducts} = useGlobalState();
  const [inputFields, setInputFields] = useState([]);
  const [selectedRow, setSelectedRow] = useState("");
  const [products2, setProducts2] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState("");
  const [result, setResult] = useState("NotEmpty");
  const [inputs, setInputs] = useState({
    id: "",
    title: "",
    price: 0,
    description: "",
    size: "",
    status: "on",
    categoryId: "",
    isPinned: 0,
  });

  const [edit, setEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(true);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  useEffect(() => {
    setProducts2(products);
  }, [products]);

  useEffect(() => {
    const handleSearch = () => {
      let filteredData = products;
      if (searchTerm.trim() !== "") {
        if (category !== "all") {
          filteredData = products.filter(
            product =>
              product.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
              (category === "" || product.category.name === category)
          );
        } else if (category === "all") {
          filteredData = products.filter(product => {
            return product.title.toLowerCase().includes(searchTerm.toLowerCase());
          });
        }
      } else if (category !== "" && category !== "all") {
        filteredData = products.filter(product => product.category.name === category);
      }
      if (products.length !== 0) {
        if (filteredData.length == 0) {
          setResult("empty");
        } else {
          setResult("NotEmpty");
        }
      }

      setProducts2(filteredData);
    };

    handleSearch();
  }, [searchTerm, category]);

  const handleSubmit = async e => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("title", inputs.title);
    formData.append("description", inputs.description);
    formData.append("categoryId", inputs.categoryId);
    formData.append("price", inputs.price);
    formData.append("size", inputs.size);
    formData.append("status", inputs.status);
    formData.append("isPinned", inputs.isPinned);
    inputFields.forEach(field => {
      formData.append("image", field.file);
      formData.append("color", field.text);
    });

    const status = validateInputFields();

    if (
      inputs.title === "" ||
      inputs.description === "" ||
      inputs.categoryId === "" ||
      inputs.status === ""
    ) {
      toast.error("Please make sure all fields are filled in correctly");
    } else {
      try {
        if (edit) {
          setLoading(true);
          const updated = await productAPI.updateProduct(inputs.id, inputs, user);
          setLoading(false);
          setProducts(products.map(value => (value.id === inputs.id ? updated : value)));
          toast.success("Edited successfully");
          setInputs({
            id: "",
            title: "",
            price: 0,
            description: "",
            size: "",
            status: "on",
            categoryId: "",
            isPinned: 0,
          });
          setInputFields([]);

          const response = await productAPI.getProducts();
          setProducts(response);
          setShowAdd(false);
          setEdit(false);
          setSelectedRow("");
        } else {
          if (!status) {
            toast.error("Please make sure all fields are filled in correctly");
          } else {
            setLoading(true);
            const newData = await productAPI.createProduct(formData, user);
            setLoading(false);
            setProducts([...products, newData]);
            toast.success("New data added successfully");
            setInputs({
              id: "",
              title: "",
              price: 0,
              description: "",
              size: "",
              status: "on",
              categoryId: "",
              isPinned: 0,
            });
            setInputFields([]);
            setShowAdd(false);
            setEdit(false);
            setSelectedRow("");

            const response = await productAPI.getProducts();
            setProducts(response);
          }
        }
      } catch (error) {
        toast.error(errorMessage(error));
      }
    }
  };

  function validateInputFields() {
    if (!Array.isArray(inputFields) || inputFields.length === 0) {
      return false;
    }

    for (const field of inputFields) {
      // Check if the object has both 'file' and 'text' properties
      if (!field.hasOwnProperty("file") || !field.hasOwnProperty("text")) {
        return false;
      }
      if (
        !field.file ||
        field.file == null ||
        field.file == undefined ||
        field.text.trim() === ""
      ) {
        return false;
      }
    }

    return true;
  }

  const handleChange = e => {
    const name = e.target.name;
    setInputs({...inputs, [name]: e.target.value});
  };

  const handleDelete = async id => {
    if (window.confirm("Are you sure you want to delete?")) {
      try {
        await productAPI.deleteProduct(id, user);
        setProducts(products.filter(data => data.id !== id));
        toast.success("Deleted successfully");
      } catch (error) {
        toast.error(errorMessage(error));
      }
    }
  };

  const handleEdit = id => {
    setEdit(true);
    setShowAdd(true);
    let target = products.find(value => value.id === id);
    setInputs(target);
    setSelectedRow(id);
  };

  const handleAddClick = () => {
    setShowAdd(!showAdd);
    if (showAdd) {
      setSelectedRow("");
      setEdit(false);
      setInputs({
        id: "",
        title: "",
        price: 0,
        description: "",
        size: "",
        status: "on",
        categoryId: "",
        isPinned: 0,
      });
    }
  };

  const handleClear = e => {
    e.preventDefault();
    setInputs({
      id: "",
      title: "",
      price: 0,
      description: "",
      size: "",
      status: "on",
      categoryId: "",
      isPinned: 0,
    });
    setInputFields([]);
    setSelectedRow("");
  };

  const handleDeleteImage = (e, id) => {
    e.preventDefault();

    if (window.confirm("Are you sure you want to delete ?")) {
      const filteredFields = inputFields.filter((item, index) => index !== id);
      setInputFields(filteredFields);
    }
  };

  return (
    <div className="w-100 container">
      {user && (
        <>
          <div className="w-100 text-dark fs-4 text-center py-2 my-2">Product Management</div>

          <div className="d-flex w-100">
            <div className="flex-fill me-2">
              <select
                id="category"
                name="category"
                className="form-select m-0 "
                aria-label="Default select example"
                value={category}
                onChange={e => setCategory(e.target.value)}
              >
                <option defaultValue value="all">
                  All
                </option>
                {categories.map(category => (
                  <option value={category.name} key={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex-fill">
              <input
                className="form-control"
                type="search"
                placeholder="Search"
                aria-label="Search"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
              <div> </div>
            </div>
          </div>

          {result == "empty" && <p className="container fs-4 text-danger">No result</p>}

          <div className="m-2">
            <div>
              <button onClick={handleAddClick} className="btn btn-secondary mb-2">
                {showAdd ? "Hide" : "Add"}
              </button>
            </div>
            {showAdd && (
              <ProductForm
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                setInputFields={setInputFields}
                handleClear={handleClear}
                handleDeleteImage={handleDeleteImage}
                inputFields={inputFields}
                inputs={inputs}
                categories={categories}
                edit={edit}
                loading={loading}
              />
            )}
          </div>

          <div className="2">
            <ProductTable
              products={products2}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              selectedRow={selectedRow}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Product;
