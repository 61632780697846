import Carousel from "../Public/Home/carousel";
const ProductTable = ({products, handleDelete, selectedRow, handleEdit}) => {
  console.log(products);
  return (
    <div className="horizontalTable table-container">
      <table className="table table-striped table-responsive">
        <thead>
          <tr>
            <th>No</th>
            <th>Image</th>
            <th>Title</th>
            <th>Description</th>
            <th>Price</th>
            <th>Size</th>
            <th>Status</th>
            <th>Category</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product, index) => (
            <tr
              key={index}
              onClick={() => handleEdit(product.id)}
              className={product.id === selectedRow ? "selected-row" : ""}
            >
              <td>{index + 1}</td>
              <td>
                <div>
                  <Carousel images={product.images} cart={true} LinkStatus={true} />
                </div>
              </td>
              <td>{product.title} </td>
              <td>{product.description} </td>
              <td>{product.price} </td>

              <td>{product.size} </td>
              <td>{product.status}</td>

              <td>{product.category ? product.category.name : "DELETED"}</td>

              <td>
                <button className="border-0" onClick={() => handleDelete(product.id)}>
                  <i className="fa fa-trash text-danger" aria-hidden="true"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductTable;
