import {useEffect, useState} from "react";
import {useParams, useNavigate} from "react-router-dom";

import {toast} from "react-toastify";
import productAPI from "../../API/productAPI";
import {errorMessage} from "../../util/error";
import {useGlobalState} from "../../context/GlobalProvider";

const ProductDetail = () => {
  const navigate = useNavigate();
  const {productId} = useParams();
  const {products, cartItems, setCartItems} = useGlobalState();

  const [size, setSize] = useState("");
  const [color, setColor] = useState("");
  const [image, setImage] = useState("");
  const [product, setProduct] = useState("");
  const [quantity, setQuantity] = useState(1);

  function getImageUrlByColor(color) {
    const matchingImage = product.images.find(image => image.color === color);
    const response = matchingImage ? matchingImage.imageUrl : null;
    const parts = response.split("/");
    return parts[parts.length - 1];
  }

  useEffect(() => {
    try {
      const fetchProduct = async () => {
        const response = await productAPI.getProduct(productId);

        if (response.size) {
          const sizes = response.size.split(" ");
          setSize(sizes[0]);
        }

        setColor(response.images[0].color);
        setProduct(response);
      };

      if (products.length == 0) {
        fetchProduct();
      } else {
        const response = products.find(x => x.id === Number(productId));

        if (response.size) {
          const sizes = response.size.split(" ");
          setSize(sizes[0]);
        }

        setColor(response.images[0].color);
        setProduct(response);
      }
    } catch (error) {
      toast.error(errorMessage(error));
    }
  }, [productId]);

  useEffect(() => {
    if (product) {
      const image = getImageUrlByColor(color);
      setImage(image);
    }
  }, [product, color]);

  const handleQuantity = e => {
    const name = e.target.name;
    if (name == "increase") {
      setQuantity(quantity + 1);
    } else {
      setQuantity(quantity - 1);
    }
  };

  const handleAddCart = () => {
    const newCartItem = {
      product: product.id,
      title: product.title,
      price: product.price,
      image: getImageUrlByColor(color),
      quantity: quantity,
      size: size.trim(),
      color: color,
      description: product.description,
    };

    const newCartItems = [...cartItems, newCartItem];
    localStorage.setItem("cartItems", JSON.stringify(newCartItems));
    setCartItems(prevCartItems => [...prevCartItems, newCartItem]);
    navigate("/cart");
  };

  return (
    <div className="container m-auto mt-3 row justify-content-around">
      <div className="col-md-6">
        <img src={`${process.env.REACT_APP_API_URL}/${image}`} className="d-block w-100" />
      </div>
      <div className="flex-fill col-md-6">
        <h2>{product.title}</h2>
        <p>{product.description}</p>

        {product.size && (
          <div>
            <label htmlFor="size" className="fw-bold">
              Size
            </label>
            <div>
              <select
                id="size"
                name="cars"
                className="text-center py-2 rounded"
                style={{width: 140}}
                value={size}
                onChange={e => setSize(e.target.value)}
              >
                {product.size
                  .trim()
                  .split(" ")
                  .map((size, index) => (
                    <option key={index} value={size}>
                      {size}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        )}

        {product.images && product.images.length > 0 && product.images[0].color !== "-" && (
          <div>
            <label htmlFor="size" className="fw-bold">
              Color
            </label>
            <div>
              <select
                dd="size"
                name="cars"
                className="text-center py-2 rounded"
                style={{width: 140}}
                value={color}
                onChange={e => setColor(e.target.value)}
              >
                {product.images.map((image, index) => (
                  <option key={index} value={image.color}>
                    {image.color}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}

        <div>
          <label htmlFor="number" className="fw-bold">
            Quanity
          </label>
          <div className="d-flex my-2">
            <button
              className="border border-end-0 border-dark p-2 bg-white "
              id="decrease"
              name="decrease"
              onClick={e => handleQuantity(e)}
            >
              -
            </button>

            <input
              type="number"
              id="number"
              value={quantity}
              onChange={e => setQuantity(Number(e.target.value))}
              className="border border-dark border-start-0 border-end-0 text-center"
              style={{width: 90}}
            />
            <button
              className="border border-start-0 border-dark text-center p-2 bg-white"
              id="increase"
              name="increase"
              onClick={e => handleQuantity(e)}
            >
              +
            </button>
          </div>
        </div>
        <button className="addToCart" onClick={handleAddCart}>
          Add To Cart
        </button>
      </div>
    </div>
  );
};

export default ProductDetail;
