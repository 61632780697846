import {Link} from "react-router-dom";
import {useGlobalState} from "../../../context/GlobalProvider";

const Admin = () => {
  const {setUser} = useGlobalState();

  const logout = async () => {
    localStorage.removeItem("user");
    setUser(null);
  };

  return (
    <div className="them2">
      <Link
        className="theme text-decoration-none ms-4 dropdown-toggle fs-5"
        to="#"
        role="button"
        data-bs-toggle="dropdown"
      >
        <i className="fa-solid them2 fa-user"></i>
      </Link>
      <ul className="dropdown-menu">
        <li>
          <Link className="dropdown-item" to="/admin/products">
            Products
          </Link>
        </li>
        <li>
          <Link className="dropdown-item" to="/admin/category">
            category
          </Link>
        </li>
        <li>
          <Link className="dropdown-item" onClick={logout}>
            Logout
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Admin;
